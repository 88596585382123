import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Row from "../components/row"
import Spacer from "../components/spacer"
import SectionTitle from "../components/sectionTitle"
import Slider from "../components/slider"
import Arrow from "../components/arrow"
import Banner from "../components/banner"
import Button from "../components/button"
import Image from "../components/image"

import l from "../components/layout.module.scss" // layout styles
import p from "./product.module.scss" // product page style

const ProductPage = ({ pageContext }) => {
  const {
    product_name,
    title: subTitle,
    description,
    hero_video = "",
    image,
    specifications_title,
    specifications = [],
    specifications_2 = [],
    slider,
    banner,
  } = pageContext.acf.product

  const checkValiditySlides = slides => {
    let placeholderIndex = slides.findIndex(s => s.alt_text === "placeholder")
    // if place exists, return false so slider won't render
    if (placeholderIndex >= 0) return false
    return true
  }

  return (
    <Layout>
      <SEO title={product_name} />
      <Row backgroundColor="#f2f2f2">
        <div className={[l.col1, l.title_section].join(" ")}>
          <h1 className={l.title}>{product_name}</h1>
          <h3 className={l.sub_title}>{subTitle}</h3>
        </div>
        <div className={l.section_grid_05}>
          <div>
            <div
              className={l.custom_content}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
            <div className={l.button_container}>
              <Button type={{ tag: "a", url: "#specificaties" }} color="black">
                Specificaties
              </Button>
              <Button type={{ tag: "a", url: banner.url }}>
                <Arrow fontSize="18px" fontWeight="bold">
                  Offerte
                </Arrow>
              </Button>
            </div>
          </div>
          {hero_video ? (
            <div className={p.videoWrapper}>
              <iframe
                title="Product video"
                src={hero_video}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                allowFullScreen
              ></iframe>
            </div>
          ) : image ? (
            <Image data={image} />
          ) : null}
        </div>
        <Spacer />
      </Row>
      {specifications[0].key && (
        <Row id="specificaties">
          <SectionTitle title={specifications_title} />
          <div className={l.section_grid_05}>
            <div>
              {specifications &&
                specifications.map(
                  s =>
                    s.key && (
                      <div key={s.key} className={p.specification_grid}>
                        <span>{s.key}</span>
                        <span>{s.value}</span>
                      </div>
                    )
                )}
            </div>
            <div>
              {specifications_2 &&
                specifications_2.map(s => (
                  <div key={s.key} className={p.specification_grid}>
                    <span>{s.key}</span>
                    <span>{s.value}</span>
                  </div>
                ))}
            </div>
          </div>
        </Row>
      )}
      {checkValiditySlides(slider) && (
        <Row>
          <Slider color="rgba(46, 46, 46, 0.6)" slides={slider} />
        </Row>
      )}
      <Row>
        <Banner data={banner} />
      </Row>
    </Layout>
  )
}

export default ProductPage
